import React, { useState, useEffect, useCallback } from "react";
import DropDown from "./DropDown";
import FiltersList from "./FiltersList";
import GroupsList from "./GroupsList";
import HandleExcelChoice from "./forms/DisplayTable/HandleExcelChoice";
import "../css/modal.css";
import "../css/DisplayTable.css";

const URL = process.env.REACT_APP_API_URL;

const DisplayTable = ({ table, active, onUpdateLoading }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState({});
  const [filterData, setFilterData] = useState({});
  const [excelForm, setExcelForm] = useState(false);

  // Updates filter list with children selected checkboxes
  function updateFilterList(childId, selectedCheckboxes) {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      if (selectedCheckboxes) {
        currentFilters[childId] = selectedCheckboxes;
      } else {
        delete currentFilters[childId];
      }
      return currentFilters;
    });
  }

  // Updates the exportFilter hook with all the data of the choosed dropdoowns
  function updateExportFilter(childId, dropdownOptions) {
    setFilterData((prevState) => {
      const currentData = { ...prevState };
      if (dropdownOptions) {
        currentData[childId] = dropdownOptions;
      } else {
        delete currentData[childId];
      }
      return currentData;
    });
  }

  // Requests backend to generate a xsls and downloads it
  async function exportExcel() {
    if (meta >= 1000000) {
      alert("El maximo de filas de un excel son 1 millon");
      return;
    }
    onUpdateLoading(true);
    setExcelForm(false);
    try {
      const response = await fetch(`${URL}/export/excel/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        throw new Error("Error al exportar Excel");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `exported_${table}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el Excel:", error);
    } finally {
      onUpdateLoading(false);
    }
  }

  // Copies to clipboard a excel like filter
  async function exportFilter() {
    setExcelForm(false);
    if (!filterData || Object.keys(filterData).length === 0) {
        alert("No hay nada que copiar");
        return;
    }

    // Obtain column order
    const columnOrder = data.length > 0 ? Object.keys(data[0]) : [];
    const headers = columnOrder.filter((key) => key in filterData);
    let result = [];

    // Set headers for the first row
    let headerRow = headers.map((key) => `${key}`).join("\t");
    result.push(headerRow.trim());

    // Add second row quantity value of most repeated element
    let firstRow = headers.map((key) => {
        const firstEntry = filterData[key][0];
        return firstEntry ? `${firstEntry.count}` : "";
    });
    result.push(firstRow.join("\t"));

    // Add the remaining data
    let remainingRows = [];
    for (let i = 0; i < Math.max(...Object.values(filterData).map((array) => array.length)); i++) {
        let row = headers.map((key) => {
            const entry = filterData[key][i];
            if (entry) {
                return `${i + 1}`; 
            } else {
                return ""; // skip if empty
            }
        });
        remainingRows.push(row.join("\t"));
    }

    result.push(...remainingRows);

    // Copy data to clipboard
    const finalOutput = result.join("\n");
    try {
        await navigator.clipboard.writeText(finalOutput);
        alert("Datos copiados al portapapeles");
    } catch (err) {
        console.error("Error al copiar al portapapeles: ", err);
        alert("No se pudo copiar al portapapeles");
    }
}

  // Gets base table without any filters
  const getTable = useCallback(async () => {
    try {
      onUpdateLoading(true);
      const response = await fetch(`${URL}/tables/${table}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setFilters({});
      onUpdateLoading(false);
    } // eslint-disable-next-line
  }, []);

  // Only execute when rendering component - Loads base table
  useEffect(() => {
    if (active) getTable(); // eslint-disable-next-line
  }, []);

  // ---------- //
  return (
    <div className="container">
      <div className="row">
        <div className="column">
          <FiltersList
            table={table}
            filters={filters}
            setData={setData}
            setMeta={setMeta}
            setFilters={setFilters}
            onReset={getTable}
            onUpdateLoading={onUpdateLoading}
            />
        </div>
        <div className="column">
          <GroupsList
            tableName={table}
            onUpdateLoading={onUpdateLoading}
            setData={setData}
            setMeta={setMeta}
            onReset={getTable}
          />
        </div>
      </div>

      {data && data.length !== 0 ? (
        <div className="historial-section">
          <h2>{table}</h2>
          <label>({meta} lineas)</label>
          <button onClick={() => setExcelForm(true)}>Exportar</button>
          <br></br>
          <br></br>
          <div className="table-div">
            <table>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th key={key}>
                      <DropDown
                        tableName={table}
                        columnName={key}
                        checkboxes={filters[key]}
                        onUpdateFilter={updateFilterList}
                        onUpdateExport={updateExportFilter}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>No hay datos para mostrar</p>
      )}

    {excelForm && (
      <HandleExcelChoice 
        setExcelForm={setExcelForm}
        onExportExcel={exportExcel}
        onExportFilter={exportFilter}
      />
    )}
    </div>
  );
};

export default DisplayTable;
