import React, { useState } from "react";
import "../css/Forms.css";

const URL = process.env.REACT_APP_API_URL;

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState("");
  const [failedLogin, setFailedLogin] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await fetch(`${URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("token", data.token);
        onLogin(true);
      } else {
        onLogin(false);
        setFailedLogin(true);
      }
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
        console.error("Failed to conect to the server: ", err);
      } 
    }
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Iniciar Sesión</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="password">
            <b>Contraseña</b>
          </label>
          <input
            type="password"
            id="password"
            placeholder="Introduce tu contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {failedLogin && <p className="error">Contraseña incorrecta</p>}
          <div className="button-group">
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
