// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.modal-content label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
}

.modal-content input, select {
  padding: 0.2rem;
  width: 100%;
  box-sizing: border-box;
}

.modal-content .button-group {
  display: flex;
  justify-content: flex-start;
  gap: 0.6rem;
  justify-content: center;
}

.modal-content button {
  padding: 0.2rem 0.5rem;
  margin: 0.25rem;
  width: auto;
  cursor: pointer;
}

.modal-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem;
}

.set-draw-inputs {
  display: flex;
  gap: 10px;
}

.set-draw-inputs input {
  width: 3rem;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/css/Forms.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".modal-content form {\n  display: flex;\n  flex-direction: column;\n  gap: 0.8rem;\n}\n\n.modal-content label {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  font-size: 1rem;\n}\n\n.modal-content input, select {\n  padding: 0.2rem;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.modal-content .button-group {\n  display: flex;\n  justify-content: flex-start;\n  gap: 0.6rem;\n  justify-content: center;\n}\n\n.modal-content button {\n  padding: 0.2rem 0.5rem;\n  margin: 0.25rem;\n  width: auto;\n  cursor: pointer;\n}\n\n.modal-title {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: 1.5rem;\n  font-weight: bold;\n  margin: 0.5rem;\n}\n\n.set-draw-inputs {\n  display: flex;\n  gap: 10px;\n}\n\n.set-draw-inputs input {\n  width: 3rem;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
