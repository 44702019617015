import { useState } from "react";
import "../../../css/Forms.css";

const SaveCombinationForm = ({
  groupList,
  setFormData,
  setIsDrawFormActive,
  onSave,
}) => {
  const [name, setName] = useState("");
  const [draw, setDraw] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  function handleGroupChange(e) {
    const value = e.target.value;
    setSelectedGroup(value === "" ? null : Number(value));
  }

  function handleSetDrawChange(index, value) {
    const updatedSetDraw = [...draw];
    updatedSetDraw[index] = value ? parseInt(value, 10) : "";
    setDraw(updatedSetDraw);
  }

  // Stores a new filter into the db
  async function saveFilter(e) {
    e.preventDefault();

    if (!name) {
      alert("Por favor introduce el nombre del filtro.");
      return;
    }

    if (draw.some((value) => !value)) {
      alert("Por favor, introduce todas las combinaciones.");
      return;
    }

    const newFilter = {
      name: name,
      groupId: selectedGroup,
    };

    const sortedDraw = draw.sort((a, b) => a - b);
    sortedDraw.forEach((element, index) => {
      newFilter[`n${index + 1}`] = element;
    });

    onSave(newFilter);
  }

  // Resets form
  function resetForm() {
    setFormData(null);
    setIsDrawFormActive(false);
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={saveFilter}>
          <div>
            <label>
              <b>Nombre del filtro</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
            </label>
          </div>

          <div>
            <label>
              <b>Combinacion del sorteo</b>
            </label>
            <div className="set-draw-inputs">
              {[...Array(5)].map((_, index) => (
                <input
                  key={index}
                  type="number"
                  min="1"
                  max="50"
                  value={draw[index] || ""}
                  onChange={(e) => handleSetDrawChange(index, e.target.value)}
                  placeholder={`N${index + 1}`}
                />
              ))}
            </div>
          </div>

          <div>
            <label>
              <b>Seleccionar grupo</b>
              <select
                value={selectedGroup === null ? "Sin grupo" : selectedGroup}
                onChange={handleGroupChange}
                style={{ maxHeight: '150px', overflowY: 'auto'}}
              >
                <option value="">Sin grupo</option>
                {groupList.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="button-group">
            <button type="button" onClick={resetForm}>
              Cancelar
            </button>
            <button type="submit">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveCombinationForm;
