import React, { useEffect } from "react";
import "../css/Navbar.css";

const URL = process.env.REACT_APP_API_URL;

export default function Navbar({
  table,
  active,
  onUpdateTable,
  onUpdateActivity,
  onUpdateLoading,
}) {
  // Deletes old tables
  async function reset() {
    onUpdateLoading(true);
    try {
      const res = await fetch(`${URL}/tables/reset`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!res.ok) {
        alert("Error al preparar los datos");
        throw new Error("Failed to restart temporal tables");
      } 

      onUpdateActivity(false);
      onUpdateTable("base");
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else {
        console.error("Error restarting: ", err);
      }
    } 
    
    finally {
      onUpdateLoading(false);
    }
  }

  // Creates a new table to work with
  async function start() {
    if (table !== "base") {
      onUpdateLoading(true);
      try {
        const res = await fetch(`${URL}/tables/start/${table}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!res.ok) {
          alert("Error al generar los datos");
          throw new Error("Failed to create temporal table");
        } 

        onUpdateActivity(true);
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else {
          console.error("Error starting: ", err);
          onUpdateActivity(false);
        }
      } 
      
      finally {
        onUpdateLoading(false);
      }
    }
  }

  // Only execute when rendering component
  useEffect(() => {
    reset(); // eslint-disable-next-line
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-title">Loto App</div>

      <div className="navbar-dropdown">
        <select
          className="navbar-select"
          value={table}
          onChange={(event) => onUpdateTable(event.target.value)}
          disabled={active}
        >
          <option className="placeholder" value="base" disabled>
            Elige una opción...
          </option>
          <option value="Historial">Historial</option>
          <option value="Combinada">Combinada</option>
        </select>
      </div>

      <div className="navbar-buttons">
        <button
          disabled={table === "base"}
          className={active ? "navbar-button-reset" : "navbar-button-start"}
          onClick={active ? reset : start}
        >
          {active ? "Reset" : "Inicio"}
        </button>
      </div>
    </nav>
  );
}
