import React, { useState, useEffect } from "react";
import "../css/DropDown.css";

const URL = process.env.REACT_APP_API_URL;

const DropDown = ({ tableName, columnName, checkboxes, onUpdateFilter, onUpdateExport }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // Updates selected checboxes here and in the parent
  function handleCheckboxChange(checkboxValue) {
    const newSelectedCheckboxes = selectedCheckboxes.includes(checkboxValue)
      ? selectedCheckboxes.filter((item) => item !== checkboxValue)
      : [...selectedCheckboxes, checkboxValue];

    setSelectedCheckboxes(newSelectedCheckboxes);
    onUpdateFilter(columnName, newSelectedCheckboxes);
  }

  // Select | Deselect all checkboxes
  function handleAllCheckboxChange(event) {
    if (event.target.checked) {
      const newOptions = dropdownOptions.map((option) => option.name);
      setSelectedCheckboxes(newOptions);
      onUpdateFilter(columnName, newOptions);
    } else {
      setSelectedCheckboxes([]);
      onUpdateFilter(columnName, null);
    }
  }

  // Updates component when parent changes props
  useEffect(() => {
    setSelectedCheckboxes(checkboxes || []);
    setIsOpen(!!checkboxes);
  }, [checkboxes]);

  // Gets column data when opening the dropdown
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `${URL}/tables/${tableName}/${columnName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          alert("Error al cargar datos del desplegable");
          throw new Error("Failed to open dropdown");
        }

        const data = await response.json();
        setDropdownOptions(data);
        onUpdateExport(columnName, data);
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else {
          console.error("Error fetching options: ", err);
        }
      }
    };

    if (isOpen) fetchOptions();
    else {
      onUpdateFilter(columnName, null);
      onUpdateExport(columnName, null); 
    }// eslint-disable-next-line
  }, [isOpen]);

  // ---------- //
  return (
    <div className="dropdown-main">
      <div>
        <span
          className="dropdown-title"
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        >
          {columnName}
        </span>
      </div>
      {isOpen && (
        <div className="horizontal-line">
          <div className="dropdown-all">
            <span htmlFor={`select-all-${columnName}`}>Todo</span>
            <input
              type="checkbox"
              id={`select-all-${columnName}`}
              onChange={handleAllCheckboxChange}
              checked={
                selectedCheckboxes.length === dropdownOptions.length &&
                dropdownOptions.length > 0
              }
            />
            <span htmlFor={`select-all-${columnName}`}>{dropdownOptions.length}</span>
          </div>
          {dropdownOptions.map((column, index) => (
            <div className="dropdown-checkboxes" key={index}>
              <span htmlFor={`checkbox-${columnName}-${index}`}>({column.count})</span>
              <span htmlFor={`checkbox-${columnName}-${index}`}>{column.name}</span>
              <input
                type="checkbox"
                id={`checkbox-${columnName}-${index}`}
                value={column.name}
                onChange={() => handleCheckboxChange(column.name)}
                checked={selectedCheckboxes.includes(column.name)}
              />
              <span htmlFor={`checkbox-${columnName}-${index}`}>{index + 1}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
