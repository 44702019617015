// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-main {
  display: inline-block;
  width: max-content;
}

.dropdown-title {
  cursor: pointer;
}

.dropdown-all {
  display: grid;
  grid-template-columns: 2fr 0.25fr 0.25fr;
  align-items: center;
  justify-items: center;
}

.dropdown-checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr 0.25fr;
  align-items: center;
  justify-items: center;
}

.horizontal-line {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 0.25rem;
  padding-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/css/DropDown.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,wCAAwC;EACxC,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,4CAA4C;EAC5C,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".dropdown-main {\n  display: inline-block;\n  width: max-content;\n}\n\n.dropdown-title {\n  cursor: pointer;\n}\n\n.dropdown-all {\n  display: grid;\n  grid-template-columns: 2fr 0.25fr 0.25fr;\n  align-items: center;\n  justify-items: center;\n}\n\n.dropdown-checkboxes {\n  display: grid;\n  grid-template-columns: 1fr 1fr 0.25fr 0.25fr;\n  align-items: center;\n  justify-items: center;\n}\n\n.horizontal-line {\n  border-top: 1px solid black;\n  border-bottom: 1px solid black;\n  margin-top: 0.25rem;\n  padding-top: 0.25rem;\n  margin-bottom: 0.25rem;\n  padding-bottom: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
