import "../../../css/Forms.css";

const HandleExcelChoice = ({ setExcelForm, onExportExcel, onExportFilter }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-title">
          <label>¿Que exportar?</label>
        </div>
        <div className="button-group">
          <button onClick={onExportExcel}>Excel</button>
          <button onClick={onExportFilter}>Filtro</button>
        </div>
        <button onClick={() => setExcelForm(false)}>Cancelar</button>
      </div>
    </div>
  );
};

export default HandleExcelChoice;
