// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th,
td {
  text-align: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.table-div {
  overflow-x: auto;
  max-height: calc(100vh - 200px);
}

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1 1;
  max-width: 45%;
}

thead {
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/css/DisplayTable.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["th,\ntd {\n  text-align: center;\n  padding-right: 0.5rem;\n  padding-left: 0.5rem;\n}\n\n.table-div {\n  overflow-x: auto;\n  max-height: calc(100vh - 200px);\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n}\n\n.row {\n  display: flex;\n  justify-content: space-between;\n}\n\n.column {\n  flex: 1;\n  max-width: 45%;\n}\n\nthead {\n  vertical-align: top;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
